body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-size: 16px;
  font-family: 'Noto Sans',sans-serif;
}
label {
  font-size: 0.875em;
}
.home-contact {
  background: white;
  border: 1px solid #1976d2;
  border-radius: 5px;
}
.divider {
  border: 1px dashed #1976d2;
}
.rc-blue {
  color: #1976d2;
}
.subtitle {
  font-size: 0.875em;
}
.title {
  font-size: 1.125em;
  font-weight: bold;
}
.summary {
  font-style: italic;
}
.rc-border {
  border: 1px solid;
  border-radius: 5px;
}
.rc-fields {
  border: 1px solid #c1c1c1;
  border-radius: 5px;
}
.rc-textbox {
  border: none;
  font-size: 0.875em;
}
.rc-textbox:focus {
  outline: none;
}
.rc-textbox::-webkit-input-placeholder {
  color: #c1c1c1;
  opacity: 0.8;
}
.rc-textbox::-moz-placeholder {
  color: #c1c1c1;
  opacity: 0.8;
}
.rc-textbox::-ms-input-placeholder {
  color: #c1c1c1;
  opacity: 0.8;
}
.rc-textbox::placeholder {
  color: #c1c1c1;
  opacity: 0.8;
}
.rc-textbox::-moz-placeholder {
  color: #c1c1c1;
  opacity: 0.8;
}
.rc-textbox:-ms-input-placeholder {
  color: #c1c1c1;
  opacity: 0.8;
}
.rc-textbox::-webkit-input-placeholder {
  color: #c1c1c1;
  opacity: 0.8;
}
.rc-submit {
  background: #2196f3;
  border-radius: 40px;
  font-size: 1em;
}
.rc-badges {
  text-align: center;
}
.rc-badge {
  width: 65px;
  height: auto;
}

.arrow {
  text-align: center;
  margin: 8% 0;
}
.arrow a {
  color: black; 
  text-decoration: none; 
  cursor: default;
}
.bounce {
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px);
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px);
  }
}

#apppage .gradient {
  background: rgba(0, 0, 0, 0.65);
}

#apppage .view {
  background-image: url('/img/rc-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 100vh;
}

#apppage h6 {
  line-height: 1.7;
}

#apppage .navbar {
  -webkit-transition: background .5s ease-in-out,padding .5s ease-in-out;
  transition: background .5s ease-in-out,padding .5s ease-in-out;
}

#apppage .top-nav-collapse {
  background: #424f95 !important;
}

@media (max-width: 768px) {
  #apppage .navbar:not(.top-nav-collapse) {
      background: #424f95 !important;
  }
}
